import { t } from 'i18next';
import {v4 as uuidv4 } from 'uuid'
const extarnal_api_http = process.env.REACT_APP_EXTERNAL_API_HTTP
const external_api_ws = process.env.REACT_APP_EXTERNAL_API_WS
function ExternalAPI() {
  this.addInput("value","object")
  this.addInput("regenerate url",-1)
  this.addInput("send",-1)
  this.addOutput("url", "string");
  this.send = ()=>console.log("ws is not initialized")
  this.pingInterval = 1000;
  this.size[0] = 180
  this.generateHash();
  this.initWS();
  this.lastSent = new Date();
}

ExternalAPI.type = "ExternalAPI";
ExternalAPI.title = t("ExternalAPI");


ExternalAPI.prototype.initWS = function (){
  console.log("ws reconnecting...")
  this.connected = false;
  this.ws = new WebSocket(external_api_ws);
  this.ws.onerror = (err)=>{
    this.connected = false;
    console.log('ws error',err)
    setTimeout(this.initWS.bind(this),300)
  }
  this.ws.onclose = (event)=>{
    console.log('ws close')
    if(!event.wasClean){
      this.connected = false;
      setTimeout(this.initWS.bind(this),300)
    }
  }
  this.ws.onmessage = message=>{
    if(!this.connected) console.log("ws connected")
    this.connected = true;
  }
  this.send = (type,hash, value)=>{
    try{
      let msg = {type, hash, value}
      this.ws.send(JSON.stringify(msg))
    }
    catch(e){
      console.log("error while sending: ",e)
    }
  }
}

ExternalAPI.prototype.generateHash = function() {
  let hash = uuidv4();
  this.hash = hash
  this.connected = false;
};


ExternalAPI.prototype.onExecute = function() {
  // let value = this.getInputData(0)
  if(!this.connected){
    this.url = "connecting..."
  }
  else{
    this.url = extarnal_api_http + '/hash/'+this.hash
  }
  if(new Date() - this.lastSent>this.pingInterval){
    this.send('ping',this.hash)
    this.lastSent = new Date();
  }
  this.setOutputData(0,this.url)
};


ExternalAPI.prototype.onAction = function (action){
  let value = this.getInputData(0)
  if(action=="send"){
    this.send("update", this.hash, value)
  }
  if(action=="regenerate url"){
    this.generateHash();
  }
}
export default ExternalAPI;