import React from "react";
import ReactDOM from "react-dom";
import Blockies from "react-blockies";

import { Input, FilledInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { t } from "i18next";

const INFURA_KEY = "32f4c2933abd4a74a383747ccf2d7003";

const availableChains = [
  { label: "Ethereum Mainnet", value: `https://mainnet.infura.io/v3/${INFURA_KEY}` },
  { label: "Goerli Testnet", value: `https://goerli.infura.io/v3/${INFURA_KEY}` },

  { label: "Polygon Mainnet", value: `https://polygon-rpc.com` },
  { label: "Mumbai Testnet", value: `https://polygon-mumbai.blockpi.network/v1/rpc/public` },
  { label: "BSC Mainnet", value: `https://bsc-dataseed.binance.org` },
  { label: "BSC Testnet", value: `https://data-seed-prebsc-1-s1.binance.org:8545` },

  { label: "Sepolia Testnet", value:`https://sepolia.infura.io/v3/${INFURA_KEY}`},
  { label: "Localhost", value: `http://localhost:8545` },
  { label: "Custom Chain", value: "CUSTOM" }
];

function Text() {
  this.addOutput("", "string");
  this.properties = {
    selectedValue: availableChains[0].value,
    value: availableChains[0].value
  };
  this.size = [400, 130];
}

Text.type = "Chain";
Text.title = t("Chain");

Text.prototype.onConnectionsChange = function(args) {
  console.log("onConnectionsChange", args);
};

Text.prototype.onExecute = function() {
  this.setOutputData(0, this.properties.value);
};

Text.prototype.getTitle = function() {
  if (this.flags.collapsed && this.properties.value) {
    return this.properties.value;
  }
  return this.title;
};

Text.prototype.handle = function(e) {
  this.properties.selectedValue = e.target.value;
  if (this.properties.selectedValue === "CUSTOM") {
    this.properties.value = "";
  } else {
    this.properties.value = e.target.value;
  }
  this.setOutputData(0, this.properties.value);
  this.onDrawBackground();
};

Text.prototype.handleCustom = function(e) {
  this.properties.value = e.target.value;
  this.properties.selectedValue = "CUSTOM";
  this.setOutputData(0, this.properties.value);
  this.onDrawBackground();
};

Text.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  
  if (this.flags.collapsed) {
    this.destory(); ///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  } else {
    //console.log(this.properties);
    this.render(
      <div>
        <FormControl style={{ marginTop: 8 * canvasscale, width: "100%", color: "#FFFFFF"}}>
          <Select
            id={"react-input-" + this.id}
            value={this.properties.selectedValue}
            onChange={Text.prototype.handle.bind(this)}
            style={{ textAlign: "left", width: "100%", color: "#FFFFFF", fontSize: 16*canvasscale, fontFamily: 'Arial', }}
          >
            {availableChains.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Input
          style={{ marginTop: 8 * canvasscale, width: "100%", color: "#FFFFFF" }}
          id={"react-input-" + this.id}
          label="Name"
          placeholder="Введите адрес RPC"
          value={this.properties.value}
          onChange={Text.prototype.handleCustom.bind(this)}
          margin="none"
          variant="outlined"
          inputProps={{style: {fontSize: (16 * canvasscale),fontFamily: 'Arial', padding: `${6*canvasscale}px 0  ${7*canvasscale}px`}}}
        />
      </div>
    );
  }
};

export default Text;
