import makeItem from "./makeItem";

function Demos(props) {
  const { setOpenDemosDialog } = props.RightMenuProps;

  const onClick = () => {
    setOpenDemosDialog(true);
  };

  let item = {
    tooltip: "Demo-schemes",
    iconName: "schema",
    onClick,
  };

  return makeItem(item);
}
export default Demos;
