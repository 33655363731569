import React from "react";
import { t } from "i18next";
import LangSelector from "../langSelector";

function Lang(props) {
  let [langSelectorHide, setLangSelectorHide] = React.useState(true);

  window.addEventListener("pointerdown", function (e) {
    let langSelcetorArea =
      document.getElementsByClassName("langSelcetorArea")?.[0];
    if (langSelcetorArea) {
      if (!langSelcetorArea.contains(e.target)) {
        setLangSelectorHide(true);
      }
    }
  });
  let display_none_if_en = {}
  if(window.location.host.endsWith('io'))  display_none_if_en = {display:'none'}
  return (
    <div
      style={{ margin: 5, ...display_none_if_en }}
      className="langSelcetorArea"
      onMouseEnter={() => {
        setLangSelectorHide(false);
      }}
      onMouseLeave={() => {
        setLangSelectorHide(true);
      }}
    >
      <span
        onPointerDown={() => {
          setLangSelectorHide(!langSelectorHide);
        }}
        className={`material-symbols-outlined ${
          !langSelectorHide ? "highlighted" : ""
        }`}
        style={{ cursor: "pointer" }}
        title={t("Language")}
      >
        language
      </span>
      <LangSelector hide={langSelectorHide}></LangSelector>
    </div>
  );
}
export default Lang;
