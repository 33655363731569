import { t } from "i18next";

function UtilsFromWei() {
  this.addInput("input", "number,string");
  this.addOutput("output", "number")
  this.properties = {decimals: 18}
  this.size[0] = 180
}

UtilsFromWei.type = "From Gwei";
UtilsFromWei.title = t("From Gwei");

UtilsFromWei.prototype.onExecute = function() {
  if (this.inputs[0] && this.getInputData(0)) {
    this.setOutputData(0,parseFloat(this.getInputData(0)/10**9))
  }else{
    this.setOutputData(0,null)
  }
};

export default UtilsFromWei
