import makeItem from "./makeItem";

function ColorOfModules(props) {
  const onClick = () => {
    global.graph.canvas.color_by_section =
      !global.graph.canvas.color_by_section;
  };

  let item = {
    tooltip: "Use color of modules",
    iconName: "contrast",
    onClick,
  };

  return makeItem(item);
}
export default ColorOfModules;
