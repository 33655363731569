import { t } from "i18next";

function Memory() {
  this.size = [120, 30];
  this.addInput("set", 0);
  this.addOutput("get", 0);
  this._pending = [];
}

Memory.type = "Memory";
Memory.title = t("Memory");
Memory.prototype.getTitle = function() {
  return this.title;
};

Memory.prototype.onAction = function() {
  this.properties.value = null
}

Memory.prototype.onExecute = function() {
  let nextValue = this.getInputData(0)
  if(typeof nextValue != "undefined" && nextValue != this.properties.value){
    this.properties.value = nextValue
  }
  this.setOutputData(0,this.properties.value)
};

export default Memory;
