import { t } from 'i18next';
import Module from './Module';
import { copyPrototype } from '../../utils/nodeUtils';
import walletSchema from '../../schemes/wallet.json'

function Wallet() {
  this.size = [200, 80];
  this.properties = { enabled: true, color: "e91e63" };
  this.enabled = true;

  //create inner graph
  this.subgraph = new global.LiteGraphJS.LGraph();
  this.subgraph._subgraph_node = this;
  this.subgraph._is_subgraph = true;



  this.subgraph.onTrigger = this.onSubgraphTrigger.bind(this);

  this.subgraph.onInputAdded = this.onSubgraphNewInput.bind(this);
  this.subgraph.onInputRenamed = this.onSubgraphRenamedInput.bind(this);
  this.subgraph.onInputTypeChanged = this.onSubgraphTypeChangeInput.bind(this);
  this.subgraph.onInputRemoved = this.onSubgraphRemovedInput.bind(this);

  this.subgraph.onOutputAdded = this.onSubgraphNewOutput.bind(this);
  this.subgraph.onOutputRenamed = this.onSubgraphRenamedOutput.bind(this);
  this.subgraph.onOutputTypeChanged = this.onSubgraphTypeChangeOutput.bind(this);
  this.subgraph.onOutputRemoved = this.onSubgraphRemovedOutput.bind(this);

  this.subgraph.configure(walletSchema)

  this.button = this.addWidget("button","Open", null, (v,graphcanvas)=>{
    setTimeout(()=>{
      graphcanvas.openSubgraph(this.subgraph);
    }, 10);
  });
}
Wallet.prototype = copyPrototype(Module)
Wallet.type = "Wallet";
Wallet.title = t("Wallet");

export default Wallet
