import makeItem from "./makeItem";

function Docs(props) {
  const onClick = () => {
    window.open(process.env.REACT_APP_DOCS_URL + "/docs/intro", "_blank");
  };
  let item = {
    tooltip: "Documentaion",
    iconName: "library_books",
    onClick,
  };
  
  return process.env.REACT_APP_DOCS_URL && localStorage.getItem('language') !== 'en' ? makeItem(item) : '';
}
export default Docs;
