import { t } from "i18next";

function SortBy() {
  this.addInput("obj", "");
  this.addOutput("", "");
  this.addProperty("reverse", false);
  this.addProperty("value", "");
  this.widget = this.addWidget(
    "text",
    "prop.",
    "",
    this.setValue.bind(this)
  );
  this.widgets_up = true;
  this.size = [190, 30];
  this._value = null;
}

SortBy.type = "sort by";
SortBy.title = t("sort by");

SortBy.prototype.onAdded = function() {
  this.widget.value = this.properties.value
};

SortBy.prototype.setValue = function(v) {
  this.properties.value = v;
  this.widget.value = v;
};

SortBy.prototype.getTitle = function() {
  if (this.flags.collapsed) {
    return "sort." + this.properties.value;
  }
  return this.title;
};

SortBy.prototype.onPropertyChanged = function(name, value) {
  if(name==="value") this.widget.value = value;
};

SortBy.prototype.onExecute = function() {
  let input = this.getInputData(0)
  let data = input;
  if (Array.isArray(data) && this.properties.value) {
    data = data.sort((a,b)=>{
      return (a[this.properties.value]>b[this.properties.value])^this.properties.reverse
    })
    this.setOutputData(0, data);
  }
  
};

export default SortBy
