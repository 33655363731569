import React from 'react';
import ReactDOM from 'react-dom'
import { TextareaAutosize } from '@material-ui/core';
import { t } from 'i18next';

const resetSize = [320,100]

function TextArea() {
  this.addInput("","string")
  this.addOutput("","string")
  this.size = resetSize;
  this.properties =  {value:""}
}

TextArea.type = "TextArea";
TextArea.title = t("TextArea");
TextArea.prototype.getTitle = function() {
  return this.title;
};

TextArea.prototype.onExecute = function() {
  let input = this.getInputData(0)
  if(input){
    this.properties.value = input
  }
  try{
    this.setOutputData(0,this.properties.value)
  }catch(e){}
}

TextArea.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;

  if (this.flags.collapsed) {
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div style={{marginLeft:10}}>
        <TextareaAutosize style={{overflow:'auto',background:"#262838",color:"#bbbbbb",border:"none",
        fontFamily: 'Arial',
        fontSize:12*canvasscale,
        width:(this.size[0]-80)*canvasscale,
        height:(this.size[1]-10)*canvasscale,
        resize:"none"
      }}
         minRows={3} placeholder="enter text here..." value={this.properties.value} onChange={(e)=>{
          this.properties.value = e.target.value
          this.onDrawBackground()
        }}/>
      </div>
    )
  }
};

export default TextArea
