import makeItem from "./makeItem";

function Load(props) {
  const { setOpenLoadDialog } = props.RightMenuProps;

  const onClick = () => {
    setOpenLoadDialog(true);
  };

  let item = {
    tooltip: "Load",
    iconName: "open_in_browser",
    onClick,
  };

  return makeItem(item);
}
export default Load;
