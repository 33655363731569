import makeItem from "./makeItem";

function Delete(props) {
  const { drawing } = props.RightMenuProps;

  const onClick = async () => {
    //console.log(JSON.stringify(global.graph.canvas.graph))
    global.graph.canvas.deleteSelectedNodes();
    //console.log("global.graph.canvas",global.graph.canvas)
    global.LiteGraphJS.LiteGraph.closeAllContextMenus();
    if (drawing) {
      //console.log("CLEAR INK FROM",global.graph.canvas)
      global.graph.canvas.ink = [];
      global.graph.canvas.setDirty(true);
      global.graph.canvas.graph.change();
    }
  };
  let item = {
    tooltip: "Delete Selected [delete key]",
    iconName: "delete",
    onClick,
  };

  return makeItem(item);
}
export default Delete;
