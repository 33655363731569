import { t } from "i18next";

function UtilsFromWei() {
  this.addInput("input", "number,string");
  this.addOutput("output", "number")
  this.properties = {decimals: 18}
  this.size[0] = 160
}

UtilsFromWei.type = "From Wei";
UtilsFromWei.title = t("From Wei");

UtilsFromWei.prototype.onExecute = function() {
  if (this.inputs[0] && this.getInputData(0)) {
    this.setOutputData(0,this.getInputData(0)/10**18)
  }else{
    this.setOutputData(0,null)
  }
};

export default UtilsFromWei
