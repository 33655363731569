import i18n from 'i18next';
import ru_locale from './locales/ru.json'
import en_locale from './locales/en.json'
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)

  .init({
   fallbackLng: 'en',
    debug: true,
    resources: {
        ru: {
            translation: ru_locale
        },
        en: {
            translation: en_locale
        }
      }
  });

let lang = localStorage.getItem('language')
if(!lang) {
  lang = 'en'
  if(window.location.host.endsWith('ru')){
    lang = 'ru'
  }
}
localStorage.setItem('language',lang)
i18n.changeLanguage(lang)


export default i18n;