import InitGraph from "../InitLitegraph";
import defaultScheme from "../../schemes/default.json"
import defaultSchemeEn from "../../schemes/default_en.json"

const graphFromUrl = (setLiteGraph, setLiteGraphCanvas) => {
  // console.log("MOUNT", LiteGraphJS)

  global.title = "no.code";

  const gr = InitGraph();

  const graph = gr.graph;
  const canvas = gr.canvas;

  global.graph = graph;

  let url = window.location.pathname;
  console.log("URL", url);

  var viewedNewFrontPage = localStorage.getItem("viewedNewNewNEWFrontPage");
  var data = localStorage.getItem("litegraph");

  if (viewedNewFrontPage && data) {
    graph.configure(JSON.parse(data));
    graph.canvas = canvas;
    setLiteGraph(graph);
    setLiteGraphCanvas(canvas);
  } else {
    if (!viewedNewFrontPage)
      localStorage.setItem("viewedNewNewNEWFrontPage", "true");

    //THIS IS THE DEFAULT FIRST TIME LANDING CASE
    let default_scheme = localStorage.getItem('language')=='ru'?defaultScheme:defaultSchemeEn
    global.graph.configure(default_scheme);
    graph.canvas = canvas;
    setLiteGraph(graph);
    setLiteGraphCanvas(canvas);
  }
};
export default graphFromUrl;
