export default function dragElement(elmnt,setMenu) {
    if (!elmnt) return;
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      // get the mouse cursor position at startup:
      pos2 = e.clientX || e.touches[0].clientX;
      document.onmouseup = closeDragElement;
      document.ontouchend = closeDragElement;
      // call a function whenever the cursor moves:

      document.onmousemove = elementDrag;
      document.ontouchmove = elementDrag;

      document.getElementById('mainCanvas').addEventListener('mousemove', () => {
        setMenu("");
        closeDragElement();
      })
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      setMenu("");

      if (window.innerWidth > elmnt.clientWidth) return;

      // calculate the new cursor position:
      pos1 = pos2 - (e.clientX || e.touches[0].clientX);
      pos2 = e.clientX || e.touches[0].clientX;

      let offset = elmnt.offsetLeft - pos1;

      if (offset > 0) offset = 0;
      if (offset < window.innerWidth - elmnt.clientWidth) offset = window.innerWidth - elmnt.clientWidth;

      elmnt.style.left = offset + "px";

    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.ontouchend = null;
      document.onmousemove = null;
      document.ontouchmove = null;
    }
  }