import React from 'react';
import ReactDOM from 'react-dom'
import Blockies from 'react-blockies';

import { Input, FilledInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

function Text() {
  this.properties =  {blockieSize: 50,placeholder:"",value:"",fontSize:28}
  this.size = [300, 40];
}

Text.type = "Label";
Text.title = t("Label");
Text.title_color = "#222"
Text.bgcolor ="transparent"

Text.prototype.onConnectionsChange = function(args){
  console.log("onConnectionsChange",args)
}

Text.prototype.getTitle = function() {
  if (this.flags.collapsed && this.properties.value) {
    return this.properties.value
  }
  return "";
};

Text.prototype.handle = function(e) {
    this.properties.value = e.target.value
    this.setOutputData(0,this.properties.value);
    this.onDrawBackground()
}

/*
<TextareaAutosize
  multiline={true}
  style={{opacity:0.777,width:"100%",height:40,color:"#FFFFFF",background:"#222",fontSize:this.properties.fontSize,border:"none"}}
  id="outlined-name"
  label="Name"
  placeholder={this.properties.placeholder}
  value={this.properties.value}
  onChange={Text.prototype.handle.bind(this)}
  margin="none"
  variant="outlined"
/>
 */

Text.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;

  if (this.flags.collapsed) {
    /*this.render(
      <div>

      </div>
    )*/
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div>
        <form className={"SOMECONTAINERCLASS"} noValidate autoComplete="off">
          <Input
            style={{color:"#FFFFFF",
            width: (this.size[0]-40)*canvasscale,
            height: (this.size[1]-5)*canvasscale,
            maxHeight: 2*this.properties.fontSize*canvasscale,
            fontFamily: 'Arial',
            fontSize: this.properties.fontSize*canvasscale
          }}
            id="outlined-name"
            label="Name"
            placeholder={this.properties.placeholder}
            value={this.properties.value}
            onChange={Text.prototype.handle.bind(this)}
            margin="none"
            variant="outlined"
            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
          />
        </form>
      </div>
    )
  }


};




export default Text
