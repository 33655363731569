import React from "react";
import { t } from "i18next";

let debounce;

const staticInputs = 1;
const staticOutputs = 1;
const resetSizeX = 440;
const resetSizeY = 200;

function ObjectObject() {
  this.addInput("", "object");
  this.addOutput("", "object");

  this.properties = {
    fontSize: 16,
  };
  this.size = [resetSizeX, resetSizeY];
  this.value = {
    to: "alice",
    from: "bob",
    value: 10,
  };
  this.properties.value = JSON.stringify(this.value,null,2)

}

ObjectObject.type = "Object";
ObjectObject.title = t("Object");
ObjectObject.prototype.getTitle = function () {
  return this.title;
};

ObjectObject.prototype.restoreValue = function(){
  this.value = {}
  setTimeout(()=>this.updateByMainInput(this.properties.value),100);
}

ObjectObject.prototype.onStart = function () {
  this.restoreValue();
};
ObjectObject.prototype.onAdded = function () {
  this.restoreValue();
};
ObjectObject.prototype.onConfigure = function(){
  this.restoreValue();
}

ObjectObject.prototype.removeAllInputs = function () {
  let max = this.inputs.length;
  for (let l = staticInputs; l < max; l++) {
    this.removeInput(staticInputs);
  }
};

ObjectObject.prototype.removeAllOutputs = function () {
  let max = this.outputs.length;
  for (let l = staticOutputs; l < max; l++) {
    this.removeOutput(staticOutputs);
  }
};

ObjectObject.prototype.recreateInputs = function () {
  let keys = Object.keys(this.value);
  let inputs = keys.map((key) => [key, "string,number,object", null]);
  this.addInputs(inputs);
};

ObjectObject.prototype.recreateOutputs = function () {
  let keys = Object.keys(this.value);
  let inputs = keys.map((key) => [key, typeof this.value[key], null]);
  this.addOutputs(inputs);
};

ObjectObject.prototype.storeSize = function () {
  this._restoreSize = [...this.size];
};

ObjectObject.prototype.restoreSize = function () {
  if (this._restoreSize) {
    this.size = [...this._restoreSize];
    this._restoreSize = undefined;
  }
};
ObjectObject.prototype.getInputLinksMap = function () {
  let res = {};
  for (let i = staticInputs; i < this.inputs.length; i++) {
    if (this.inputs[i].link) {
      let link_info = this.graph.links[this.inputs[i].link];
      // console.log(link_info);
      res[this.inputs[i].name] = {
        node: link_info.origin_id,
        slot: link_info.origin_slot,
      };
    }
  }
  return res;
};
ObjectObject.prototype.resotreInputLinks = function(inputLinksMap){
  for (let i = staticInputs; i < this.inputs.length; i++) {
    let info = inputLinksMap[this.inputs[i].name];
    if(!info) continue;
    let {node, slot} = info;
    node = this.graph.getNodeById(node)
    node.connect(slot, this, i);
  }
}


ObjectObject.prototype.getOutputLinksMap = function () {
  let res = {};
  // console.log(JSON.parse(JSON.stringify(this.outputs)))
  for (let i = staticOutputs; i < this.outputs.length; i++) {
    for (let link of this.outputs[i].links||[]) {
      let link_info = this.graph.links[link];
      // console.log(link_info, link);
      res[this.outputs[i].name] = {
        node: link_info.target_id,
        slot: link_info.target_slot,
      };
    }
  }
  return res;
};

ObjectObject.prototype.resotreOutputLinks = function(outputLinksMap){
  for (let i = staticOutputs; i < this.outputs.length; i++) {
    let info = outputLinksMap[this.outputs[i].name];
    if(!info) continue;
    let {node, slot} = info;
    node = this.graph.getNodeById(node)
    this.connect(i, node, slot);
  }
}



// string input
ObjectObject.prototype.updateByMainInput = function (mainInput) {
  if (mainInput=="") mainInput = "{}"
  if (!mainInput) return;
  if (mainInput === JSON.stringify(this.value)) return;// console.log("EQUALS", mainInput, this.value);
  this.storeSize(); // удаление входов меняет размер, поэтому чтобы не менять сохраняем его
  try {
    let newVal = JSON.parse(mainInput);
    if (typeof newVal !== "object") {
      throw new Error("Main input expexts object(or array)");
    }
    //console.log("value accepted:", newVal);
    this.value = newVal;
  } catch (e) {
    return console.error(e, mainInput);
  }
  // получили валидный объект в this.value
  try{
    let inputMap = this.getInputLinksMap();
    let outputMap = this.getOutputLinksMap();
    this.removeAllInputs();
    this.removeAllOutputs();
    this.recreateInputs();
    this.recreateOutputs();
    this.resotreInputLinks(inputMap);
    this.resotreOutputLinks(outputMap);
    this.properties.value = JSON.stringify(this.value, null, 2);
    this.restoreSize();
  }
  catch(e){ console.error(e)}
};

ObjectObject.prototype.updateBySecondaryInputs = function () {
  let modified = false;
  for (let i = staticInputs; i < this.inputs.length; i++) {
    try{
      let name = this.inputs[i].name;
      let inpval = this.getInputData(i)
      let newval = inpval ?? this.value[name];
      if(newval!=this.value[name]){
        this.value[name] = newval;
        modified = true; 
      }

    }
    catch(e){
      console.error(e)
    }
  }
  if(modified)  this.properties.value = JSON.stringify(this.value, null, 2);
}
ObjectObject.prototype.setSecondaryOutputs = function () {
  for (let i = staticOutputs; i < this.outputs.length; i++) {
    try{
      let name = this.outputs[i].name;
      this.setOutputData(i, this.value[name])
    }
    catch(e){
      console.error(e)
    }
  }
}
ObjectObject.prototype.onExecute = function () {
  try {
    let mainInput = JSON.stringify(this.getInputData(0));
    this.updateByMainInput(mainInput);
  } catch (e) {
    console.error("Can't update by input 0", e);
  }
  try {
    this.setOutputData(0, this.value);
  } catch (e) {console.error(e)}
  try{
    this.updateBySecondaryInputs();
    this.setSecondaryOutputs();
  }
  catch(e) {console.error(e)}

  let minSize = this.computeSize();
  if (this.size[1] < minSize[1]) {
    this.size[1] = Math.max(minSize[1]);
  }
};

ObjectObject.prototype.onDrawBackground = function (ctx) {
  let canvasscale = this.graph.canvas?.ds.scale ?? 1;
  if (this.flags.collapsed) {
    this.destory(); ///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  } else {
    this.render(
      <div key={"object_" + this.id} style={{ marginLeft: 0 }}>
        <textarea
          style={{
            background: "#262838",
            color: "#bbbbbb",
            border: "none",
            fontSize: this.properties.fontSize * canvasscale,
            letterSpacing: 1,
            width: (this.size[0] - 160) * canvasscale,
            height: (this.size[1] - 10) * canvasscale,
            resize: "none",
          }}
          rows={3}
          placeholder="{'key':'value'}"
          value={this.properties.value}
          onChange={(e) => {
            this.properties.value = e.target.value;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.updateByMainInput(this.properties.value);
            }, 500);
            this.onDrawBackground();
          }}
        />
      </div>
    );
  }
};

export default ObjectObject;
