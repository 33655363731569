import React from 'react';

import { t } from 'i18next';

function Image() {
  this.value = ""
  this.addInput("", 0);
  this.size = [300, 300];
}

Image.type = "Image";
Image.title = t("Image");
Image.prototype.onConnectionsChange = function(args){
  console.log("onConnectionsChange",args)
}

Image.prototype.getTitle = function() {
  if (this.flags.collapsed && this.value) {
    return this.value
  }
  return this.title;
};

Image.prototype.onExecute = function() {
    let input = this.getInputData(0)
    if (this.inputs[0] && typeof input != "undefined" && this.value != input ) {
      this.value = input;
    }
  };
Image.prototype.onDrawBackground = function(ctx) {
  if (this.flags.collapsed) {
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div style={{width:'100%', height:'100%'}}>
        <img src={this.value} style={{width:'100%', height:'100%'}} alt=" "></img>
      </div>
    )
  }


};




export default Image
