import React from "react";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import "litegraph.js/css/litegraph.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { t } from 'i18next'

const useStyles = makeStyles({
  button: {
  }
});



function LoadDialog(props) {
  const { liteGraph, setOpenLoadDialog, openLoadDialog, live } = props;

  const classes = useStyles();

  const [loadType, setLoadType] = React.useState(null);

  const handleClose = () => {
    setOpenLoadDialog(false);
    setLoadType(null);
  };

  const loadFromFile = async () => {
    document.getElementById("loadjsonfile").click();
    handleClose();
  };

  return (
    <>
      <div style={{ position: "absolute", bottom: -100000, left: -100000 }}>
        <span
          style={{
            border: "1px solid #777777",
            color: live ? "#00ff00" : "#0000ff",
            padding: 5,
            cursor: "pointer"
          }}
        >
          <input
            id="loadjsonfile"
            type="file"
            name="file"
            value=""
            onChange={e => {
              console.log("FILE", e.target.files[0]);
              var reader = new FileReader();
              reader.onload = event => {
                let compressedString = event.target.result;
                console.log("decompress:", compressedString);
                try{
                  let json = JSON.parse(compressedString)
                  localStorage.setItem("litegraph", JSON.stringify(json));
                  liteGraph.configure(json);
                }
                catch(e){
                  console.error(e)
                }
              };
              try {
                reader.readAsText(e.target.files[0]);
              } catch (e) {
                console.log(e);
              }
            }}
          ></input>
        </span>
      </div>
      <Dialog
        onClose={() => {
          handleClose();
        }}
        open={openLoadDialog}
        maxWidth="md"
        className="modal"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        {loadType === null && (
          <div className="modal-card">
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ margin: 0, width: "100%", padding: 32 }}
            >
              <Grid item style={{ width: 220 }}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    onClick={loadFromFile}
                    startIcon={<FileCopyIcon />}
                    style={{
                      width:'100%'
                    }}
                  >
                    {t("Load from file")}
                  </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Dialog>
    </>
  );
}

export default LoadDialog;
