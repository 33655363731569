import React from 'react';
import Graph from './pages/graph'
import { Helmet } from "react-helmet";
import './i18n';
require('dotenv').config()

function App() {
  console.log("APP");
  return (
    <div>
      <Helmet>
          {/* <!-- Chrome, Firefox OS and Opera --> */}
          <meta name="theme-color" content="#414562" />
          {/* <!-- Windows Phone --> */}
          <meta name="msapplication-navbutton-color" content="#414562" />
          {/* <!-- iOS Safari --> */}
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-transcluent" />
          {/* <!-- Яндекс.Браузер --> */}
          <meta name="viewport" content="ya-title=#000000,ya-dock=fade" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"></meta>
      </Helmet>
      <Graph />
    </div>
  );
}

export default App;
