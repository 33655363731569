import { t } from "i18next";
import { copyPrototype } from "../../utils/nodeUtils";
import Output from "./Output";
function OutputTrigger() {
  this.addInput("", -1);
  this.is_output_node = true;
  this.name_in_graph = "";
  this.properties = { name: "", type: -1 };

  this.name_widget = this.addWidget(
    "text",
    "Name",
    this.properties.name,
    (v) => {
      this.setProperty("name", v);
    }
  );

  this.widgets_up = true;
  this.size = [180, 40];
}
OutputTrigger.prototype = copyPrototype(Output);

OutputTrigger.type = "Output Trigger";
OutputTrigger.title = t("Output Trigger");

OutputTrigger.prototype.onAction = function (action, param) {
  this.graph.trigger(this.name_in_graph, param);
};

export default OutputTrigger;
