import { t } from "i18next";

function Output() {
  this.addInput("", "");
  this.is_output_node = true;
  this.name_in_graph = "";
  this.properties = { name: "" };

  this.name_widget = this.addWidget(
    "text",
    "Name",
    this.properties.name,
    (v) => {
      this.setProperty("name", v);
    }
  );

  this.widgets_up = true;
  this.size = [180, 40];
}

Output.type = "Output";
Output.title = t("Output");
Output.desc = "Output of the graph";

Output.prototype.countWithSameName = function (name) {
  let allOutputNodes = this.graph._nodes.filter((x) => x.is_output_node);
  return allOutputNodes.filter((x) => x.name_in_graph === name).length;
};
Output.prototype.onNameChanged = function (v) {
  if (v === this.name_in_graph) {
    return;
  }
  if (!this.graph) {
    // если graph все же не успел проставиться, то сбрасываем
    this.name_widget.value = "";
    this.name_in_graph = "";
    return;
  }
  if (v === "") {
    this._removeOutput();
  } else {
    let countWithSameCurrName = this.countWithSameName(this.name_in_graph);
    let countWithSameNextName = this.countWithSameName(v);
    if (this.name_in_graph && countWithSameCurrName === 1) {
      if (countWithSameNextName === 0) {
        this.graph.renameOutput(this.name_in_graph, v);
      } else {
        this.graph.removeOutput(this.name_in_graph);
      }
    } else {
      this.graph.addOutput(v, this.properties.type);
    }
  }
  this.name_widget.value = v;
  this.name_in_graph = v;
};
Output.prototype.onPropertyChanged = function (name, v) {
  if (name == "name") {
    setTimeout(() => this.onNameChanged(v), 300); //т.к. при копировании graph не проставлен нужно немного подождать
  }
};
Output.prototype.onExecute = function () {
  this._value = this.getInputData(0);
  this.graph.setOutputData(this.properties.name, this._value);
};

Output.prototype._removeOutput = function () {
  let countWithSameCurrName = this.countWithSameName(this.name_in_graph);
  if (countWithSameCurrName === 1) {
    this.graph.removeOutput(this.name_in_graph);
  }
};
Output.prototype.onRemoved = function () {
  this._removeOutput();
};

Output.prototype.getTitle = function () {
  if (this.flags.collapsed) {
    return this.properties.name;
  }
  return this.title;
};

global.LiteGraphJS.GraphOutput = Output;

export default Output;
