import makeItem from "./makeItem";

function Paste(props) {
  const onClick = async () => {
    global.graph.canvas.pasteFromClipboard(false);
    global.graph.canvas.setDirty(true);
    global.graph.canvas.graph.change();
  };

  let item = {
    tooltip: "Paste [ctrl+v]",
    iconName: "content_paste_go",
    onClick,
  };

  return makeItem(item);
}
export default Paste;
