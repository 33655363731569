import { t } from "i18next";

const Web3 = require("web3");
const EthWallet = require("ethereumjs-wallet");
const EthUtil = require("ethereumjs-util");
const Tx = require('ethereumjs-tx');

const defaultProvider =
  "https://mainnet.infura.io/v3/e59c464c322f47e2963f5f00638be2f8";

function Wallet() {
  this.addInput("[blockchain]", "string");
  this.addInput("privatekey", "string");
  this.addOutput("blockchain", "string");
  this.addOutput("privatekey", "string");
  this.addOutput("address", "string");
  this.addOutput("tx()", "function");
  this.addOutput("balance()", "function");
  this.addOutput("sign()", "function");
  this.addOutput("send()", "function");

  this.properties = {
    value: 0,
    nonce: null,
    data: "0x",
    gas: 23000,
    gasPrice: 4100000000,
  };

  this.privatekey = "";
  this.provider = defaultProvider;
  this.address = "";
}

Wallet.type = "Wallet";
Wallet.title = t("Wallet");
Wallet.title_color = "#e91e63";

Wallet.prototype.getTitle = function () {
  return this.title;
};

Wallet.prototype.onExecute = async function () {
  if (this.inputs[0] && this.getInputData(0)) {
    try {
      this.provider = this.getInputData(0);
      this.setOutputData(0, this.provider);
      this.connectWeb3();
    } catch (error) {
      console.log("error ", error);
    }
  }
  if (
    this.inputs[1] &&
    this.getInputData(1) &&
    typeof this.getInputData(1) == "string"
  ) {
    try {
      this.privatekey = this.getInputData(1);
      this.setOutputData(1, this.privatekey);
      this.onAction();
    } catch (error) {
      console.log("error ", error);
    }
  } else {
    this.privatekey = "";
  }
  this.setOutputData(3, {
    name: "transaction",
    args: [{ name: "hash", type: "string" }],
    function: async (args) => {
      if (args.hash) {
        let count = await this.web3.eth.getTransaction(args.hash);
        return count;
      }
    },
  });
  this.setOutputData(4, {
    name: "balance",
    args: [{ name: "address", type: "string" }],
    function: async (args) => {
      try {
        this.onAction();
        let balance = await this.web3.eth.getBalance(args.address);
        return balance
      } catch (e) {
        global.setSnackbar({msg:e.message})
        console.log(e);
      }
    },
  });
  this.setOutputData(5, {
    name: "sign",
    args: [{ name: "message", type: "string" }],
    function: async (args) => {
      try{
        this.onAction();
        return await this.web3.eth.accounts.sign(args.message,this.privatekey)
      }
      catch(error){
        console.log(error)
        global.setSnackbar({ msg: error.message });
      }
    },
  });
  this.setOutputData(6, {
    name: "send",
    args: [
      { name: "to", type: "string" },
      { name: "value", type: "number" },
      { name: "data", type: "string" },
      { name: "gasLimit", type: "number" },
      { name: "gasPrice", type: "number" },
      { name: "nonce", type:"string"}
    ],
    function: async (args) => {
        try{
          this.onAction();
          const TxObj = await this.getTxObject(args);
          const tx = new Tx(TxObj)
          tx.sign(Buffer.from(this.privatekey.replace('0x',''), 'hex'))
          const serializedTx = tx.serialize();
          return await this.web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
        }
        catch(error){
          console.log(error)
          global.setSnackbar({ msg: error.message });
        }
    },
  });
};

Wallet.prototype.getTxObject = async function (args) {
  const transactionParameters = {
    gasPrice: this.web3.utils.toHex(this.properties.gasPrice),
    gas: this.web3.utils.toHex(this.properties.gas),
    //gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
    //gas: '0x2710',  // customizable by user during MetaMask confirmation.
    //to: args.to, // Required except during contract publications.
    from: this.address, // must match user's active address.
    //value: ""+args.value, // Only required to send ether to the recipient from the initiating external account.
    //data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
    //chainId: 3 // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
  };

  if (typeof args.to != "undefined" && args.to != null) {
    transactionParameters.to = args.to;
  }

  if (typeof args.value != "undefined" && args.value != null) {
    transactionParameters.value =
      "" + this.web3.utils.toHex(args.value);
  }

  if (typeof args.data != "undefined" && args.data != null) {
    transactionParameters.data = args.data;
  }

  if (typeof args.gasLimit != "undefined" && args.gasLimit != null) {
    transactionParameters.gas =
      "" + this.web3.utils.toHex(args.gasLimit);
  }

  if (typeof args.gasPrice != "undefined" && args.gasPrice != null) {
    transactionParameters.gasPrice =
      "" + this.web3.utils.toHex(args.gasPrice);
  }

  if (typeof args.nonce != "undefined" && args.nonce != null) {
    transactionParameters.nonce = args.nonce;
  }
  if(!transactionParameters.nonce){
    let accountNonce = '0x' + (await this.web3.eth.getTransactionCount(this.address)).toString(16)
    transactionParameters.nonce = accountNonce;
  }
  else{
    transactionParameters.nonce = this.web3.utils.toHex(transactionParameters.nonce)
  }

  console.log("transactionParameters", transactionParameters);
  return transactionParameters;
}

Wallet.prototype.connectWeb3 = async function () {
  if (this.provider) {
    try {
      this.web3 = new Web3(this.provider);
      this.properties.gasPrice = await this.web3.eth.getGasPrice()
    } catch (e) {
      console.log(e);
    }
  }
};

Wallet.prototype.onAction = function () {
  const privateKeyBuffer = EthUtil.toBuffer(this.privatekey);
  const wallet = EthWallet.fromPrivateKey(privateKeyBuffer);
  this.address = wallet.getAddressString();
  this.setOutputData(2, this.address);
};


export default Wallet;
