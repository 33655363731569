import React from "react";
import { t } from "i18next";
import Save from "./rightmenu/save";
import Load from "./rightmenu/load";
import Demos from "./rightmenu/demos";
import Search from "./rightmenu/search";
import Reorient from "./rightmenu/reorient";
import Copy from "./rightmenu/copy";
import Paste from "./rightmenu/paste";
import Select from "./rightmenu/select";
import Delete from "./rightmenu/delete";
import ColorOfModules from "./rightmenu/colorOfModules";
import Docs from "./rightmenu/docs";
import Lang from "./rightmenu/lang";

function RightMenu(props) {
  let tools = "";
 
  if (global.graph && global.graph.canvas) {
    tools = (
      <div className="right_toolbar">
        <div className="top_block">
          <Save  RightMenuProps={props.RightMenuProps}></Save>
          <Load  RightMenuProps={props.RightMenuProps}></Load>
          <Demos RightMenuProps={props.RightMenuProps}></Demos>

          <div style={{ margin: "25px 0" }}></div>
          
          <Search         RightMenuProps={props.RightMenuProps}></Search>
          <Reorient       RightMenuProps={props.RightMenuProps}></Reorient>
          <Copy           RightMenuProps={props.RightMenuProps}></Copy>
          <Paste          RightMenuProps={props.RightMenuProps}></Paste>
          <Select         RightMenuProps={props.RightMenuProps}></Select>
          <Delete         RightMenuProps={props.RightMenuProps}></Delete>
          <ColorOfModules RightMenuProps={props.RightMenuProps}></ColorOfModules>
          <Docs           RightMenuProps={props.RightMenuProps}></Docs>
        </div>

        <div className="central_filler"></div>

        <div className="bottom_block" style={{ position: "relative" }}>
          <Lang RightMenuProps={props.RightMenuProps}></Lang>
          <div style={{ margin: 30 }}></div>
        </div>
      </div>
    );
  }
  return tools;
}
export default RightMenu;
