import { t } from 'i18next';
const extarnal_api_http = process.env.REACT_APP_EXTERNAL_API_HTTP
const axiosParent = require('axios').default;
const https = require('https')
const axios = axiosParent.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

function Compile() {
  this.addInput("solidity","string")
  this.addInput("compile",-1)
  this.addOutput("bytecode","string")
  this.addOutput("abi","object")
//  this.properties = { host: "https://solc.no.code", port:"48451" };
  this.size[0] = 210
}

Compile.type = "Compile";
Compile.title = t("Compile");


Compile.prototype.onExecute = function() {
  this.setOutputData(0,this.bytecode?"0x"+this.bytecode:this.bytecode)
  this.setOutputData(1,this.abi)
};

Compile.prototype.onAction = function() {
  let solidity = this.getInputData(0)
  if(solidity){
    this.solidity = solidity
    this.compile()
  }
}

Compile.prototype.compile = function() {
  let dependencies = {}
  let name = "Contract.sol"
  console.log("this.properties.solidity",this.solidity)
  dependencies[name] = {content: this.solidity};

  console.log("dependencies",dependencies)
  let solcObject = {
    language: 'Solidity',
    sources: dependencies,
    settings: {
      outputSelection: {
            '*': {
                '*': [ '*' ]
            }
      },
    }
  }

  console.log(" 🛠️  Compiling...",solcObject.sources)

  axios.post(extarnal_api_http+'/solc/',solcObject)
  .then((response) => {
    //console.log("response.data",response.data)
    this.compiled = response.data

    //console.log("COMPILED:",this.properties.compiled)
    if(this.compiled.errors && this.compiled.errors[0] && this.compiled.errors[0].message){
      console.log("ERRORS:",this.compiled.errors)
      for(let e in this.compiled.errors){
        if(this.compiled.errors[e].type != "Warning"){
          global.setSnackbar({msg:this.compiled.errors[e].formattedMessage})
          break;
        }
      }
    }
    console.log(this.compiled)
    let contractName = Object.keys(this.compiled.contracts[name])[0]
    if(!contractName){
      throw new Error("Contract not found")
    }
    let compiledContractObject = this.compiled.contracts[name][contractName]
    //console.log("compiledContractObject",compiledContractObject)

    if(compiledContractObject && compiledContractObject.evm ) {
      this.bytecode = compiledContractObject.evm.bytecode.object
      this.abi = compiledContractObject.abi
      global.setSnackbar({msg:"✅ Compiled",color:"#64cb53"})
    }
  })
  .catch(function (error) {
    console.log(error);
  });
}

Compile.prototype.onInputDblClick = function(index,e){
  if(index!=0){
    return false;
  }
  let code_node = global.LiteGraphJS.LiteGraph.createNode("Input/Code");
  code_node.pos = [e.canvasX - 380, e.canvasY];
  this.graph.add(code_node);
  setTimeout(() => {
    code_node.connect(0, this, index);
  }, 1)
  return true
}

export default Compile
