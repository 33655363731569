import React from 'react';
import ReactDOM from 'react-dom'
import Blockies from 'react-blockies';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Input, FilledInput, Icon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FileCopy } from '@material-ui/icons';
import { t } from 'i18next';

function Address() {
  this.addInput("", 0);
  this.properties =  {blockieSize: 50,placeholder:""}
  this.size = [340, 80];
}

Address.type = "Address";
Address.title = t("Address");

Address.prototype.getTitle = function() {
  if (this.flags.collapsed && this.value) {
    return this.value
  }
  return this.title;
};

Address.prototype.onExecute = function() {
  let input = this.getInputData(0)
  if (this.inputs[0] && typeof input != "undefined" && this.value != input ) {
    this.value = input;
  }
};

Address.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  if (this.flags.collapsed) {
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else if(this.value && typeof this.value.substr == "function") {
    this.render(
      <div style={{marginTop:10*canvasscale,textAlign:'left',marginLeft:55*canvasscale,fontSize:32*canvasscale}}>
        <div style={{position:'absolute',left:10*canvasscale,top:10*canvasscale}}>
          <Blockies
            seed={this.value&&this.value.toLowerCase?this.value.toLowerCase():this.value}
            size={8}
            scale={5*canvasscale}
          />
        </div>
        <div style={{float:"right", fontFamily: 'Arial'}}>
          <Tooltip title="Copy" style={{cursor:"pointer"}}>
            <CopyToClipboard text={this.value}
              onCopy={() => {

              }}>
              {/* <span className={'material-icons'} style={{cursor: 'pointer', fontSize: 14*canvasscale}} title="Copy" >file_copy</span> */}
              {/* <span className="material-symbols-outlined" style="cursor: pointer;" title="View">visibility</span> */}
              {/* <Icon>file_copy</Icon> */}
              <span>{"0x"+this.value.substr(2,4)+"..."+this.value.substr(-4)}</span>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
    )
  }


};




export default Address
