import { t } from 'i18next';

const EthTx = require('ethereumjs-tx');
var EthUtil = require('ethereumjs-util');
var Web3 = require('web3');

const defaultProvider = "https://mainnet.infura.io/v3/e59c464c322f47e2963f5f00638be2f8"

function Web3Transaction() {
  this.addInput("[blockchain]","string")
  this.addInput("[privatekey]","string")
  this.addInput("[to]","string")
  this.addInput("[value]","number,string")
  this.addInput("[data]","string")
  this.addInput("[gasLimit]","number,string")
  this.addInput("[gasPrice]","number,string")
  this.addInput("[nonce]","number,string")
  this.addInput("sign",-1)
  this.addOutput("transaction", "object");
  this.addOutput("signed", "string");
  this.addOutput("signed",-1)
  this.addOutput("hash","string")
  this.properties = { value: 0, nonce: null, data: "0x", gas: 23000, gasPrice: 4100000000, provider: defaultProvider, privateKey: ""  };
  this._values = JSON.parse(JSON.stringify(this.properties))
  let that = this;
  this.values = new Proxy(this._values, {
    get:function(target, name){
      return target[name] ?? that.properties[name]
    }
  })
  this.prevProvider = null;
  this.size[0]=240
  this.signed = false
}

Web3Transaction.type = "Transaction";
Web3Transaction.title = t("Transaction");

Web3Transaction.prototype.onAdded = function() {
  this.connectWeb3();
};


Web3Transaction.prototype.connectWeb3 = async function() {
  console.log(this.values.provider)
  try{
    if(this.values.provider){
      this.web3 = new Web3(this.values.provider)
      await this.updateDynamicDefaults(true);
    }
  }
  catch(e){console.log(e)}
}

Web3Transaction.prototype.updateDynamicDefaults = async function(recursive){
  try{
    this.properties.gasPrice = await this.web3.eth.getGasPrice()
  }
  catch(e){
    this.properties.getGasPrice = 4100000000;
  }
  try{
    let publicAddress = "0x"+EthUtil.privateToAddress(this.values.privateKey).toString('hex')
    this.properties.nonce = await this.web3.eth.getTransactionCount(publicAddress)
  }
  catch(e){
    this.properties.nonce = 0;
  }
  if(recursive) {
    clearTimeout(this.timeout);
    //graph == null если кубик удален
    if(this.graph!=null) this.timeout = setTimeout(this.updateDynamicDefaults.bind(this,true), 3000)
  }
}


Web3Transaction.prototype.onExecute = async function() {
  this.values.provider = this.getInputData(0);
  this.values.privateKey = this.getInputData(1);
  this.values.to = this.getInputData(2);
  this.values.value = this.getInputData(3);
  this.values.data = this.getInputData(4);
  this.values.gas = this.getInputData(5);
  this.values.gasPrice = this.getInputData(6);
  this.values.nonce = this.getInputData(7);

  if(this.prevProvider!==this.values.provider){
    await this.connectWeb3()
  }

  this.craftTransaction()



  this.setOutputData(0,this.transaction)
  this.setOutputData(1,this.signedTransaction)

  if(this.signed){
    this.signed = false
    this.trigger("signed",this.signedTransaction)
  }
  this.setOutputData(3,this.hash)

  this.prevProvider = this.values.provider;
};


Web3Transaction.prototype.onAction = async function(event, args) {
  try{
    await this.updateDynamicDefaults();
    this.craftTransaction()
    const tx = new EthTx(this.transaction);
    console.log(JSON.stringify(tx))
    tx.sign(Buffer.from(this.values.privateKey.replace("0x",""), 'hex'));
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');
    this.signedTransaction = rawTx
    console.log(" * * * SIGNED",JSON.stringify(tx))
    this.signed = true

    const raw = serializedTx.toString('hex')
    const fake = new EthTx(raw);
    this.hash = "0x"+fake.hash(true).toString('hex')

  }catch(e){
    console.log(e)
    global.setSnackbar({msg: e.message})
  }

}



Web3Transaction.prototype.craftTransaction = async function(){
  try{
    this.transaction = {
      value: parseInt(this.values.value),
      data: this.web3.utils.toHex(this.values.data),
      gas: parseInt(this.values.gas),
      gasPrice: parseInt(this.values.gasPrice),
      nonce: this.values.nonce
    }

    if(this.values.to){
      this.transaction.to = ""+this.values.to
    }
  }catch(e){console.log(e)}

}


export default Web3Transaction
