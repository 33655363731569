import React from 'react';
import ReactDOM from 'react-dom'
import Blockies from 'react-blockies';

import { Input, FilledInput, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

function Text() {
  this.properties =  {fontSize: 18,placeholder:"// comments...",value:""}
  this.size = [500, 40];
}

Text.type = "Comment";
Text.title = t("Comment");
Text.title_color = "#222"
Text.bgcolor ="transparent"

Text.prototype.onConnectionsChange = function(args){
  console.log("onConnectionsChange",args)
}

Text.prototype.getTitle = function() {
  if (this.flags.collapsed && this.properties.value) {
    return this.properties.value
  }
  return "";
};

Text.prototype.handle = function(e) {
    this.properties.value = e.target.value
    this.setOutputData(0,this.properties.value);
    this.onDrawBackground()
    if(this.properties.value) global.title = this.properties.value
}

Text.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;

  if (this.flags.collapsed) {
    /*this.render(
      <div>

      </div>
    )*/
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div>
        <form className={"SOMECONTAINERCLASS"} noValidate autoComplete="off">
          <TextareaAutosize
            style={{opacity:0.3333,color:"#FFFFFF",background:"none",border:"none",fontSize:this.properties.fontSize * canvasscale,marginTop:10 * canvasscale,
              width: (this.size[0]-40)*canvasscale,
              height: (this.size[1]-20)*canvasscale,
              fontFamily: 'Arial',
              fontSize: 18*canvasscale,
              resize: "none"
            }}
            id="outlined-name"
            label="Name"
            placeholder={this.properties.placeholder}
            value={this.properties.value}
            onChange={Text.prototype.handle.bind(this)}
            margin="none"
            variant="outlined"
          />
        </form>
      </div>
    )
  }


};




export default Text
