import makeItem from "./makeItem";

function Save(props) {
  const { handleOpenSaveDialog } = props.RightMenuProps;

  const onClick = () => {
    var data = JSON.stringify(window.graph.serialize());
    localStorage.setItem("litegraph", data);
    handleOpenSaveDialog();
  };

  let item = {
    tooltip: "Save",
    iconName: "save",
    onClick,
  };

  return makeItem(item);
}
export default Save;
