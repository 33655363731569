import makeItem from "./makeItem";

function Search(props) {
  const onClick = async () => {
    var simulatedEvent = new MouseEvent("mouseup", {
      screenX: window.innerWidth / 2,
      screenY: window.innerHeight / 2,
      clientX: window.innerWidth / 2,
      clientY: window.innerHeight / 2,
      button: 0,
    });
    global.graph?.canvas.showSearchBox(simulatedEvent);
  };

  let item = {
    tooltip: "Search block [space key]",
    iconName: "search",
    onClick,
  };

  return makeItem(item);
}
export default Search;
