import LiteGraphJS from 'litegraph.js/build/litegraph.js'
let title_height = LiteGraphJS.LiteGraph.NODE_TITLE_HEIGHT;
function getPoints(node){
    let bottom = node.pos[1];
    let top = node.pos[1]+node.size[1]+title_height;
    let left = node.pos[0];
    let right = node.pos[0]+node.size[0];
    return {top,bottom,left,right}
}
function isIntersected(node1, node2){
    let p1 = getPoints(node1)
    let p2 = getPoints(node2)
    if(p1.top    < p2.bottom) return false
    if(p1.bottom > p2.top) return false
    if(p1.left   > p2.right) return false
    if(p1.right  < p2.left) return false
    return true
}
export function setIntersected(nodes){
    for(let node of nodes){
        node.is_intersected = false
    }
    for(let [i1, node1] of nodes.entries()){
        let points1 = getPoints(node1)
        for(let [i2, node2] of nodes.entries()){
            let points2 = getPoints(node2)
            if(i1>=i2) continue
            if(isIntersected(node1,node2)){
                node1.is_intersected = true
            }

        }
    }
}