import { t } from 'i18next';

const { toChecksumAddress } = require('ethereum-checksum-address')

function ToHex() {
  this.addInput("input", "");
  this.addOutput("output", "string")
  this.size[0] = 200
}

ToHex.type = "To Checksum";
ToHex.title = t("To Checksum");

ToHex.prototype.onExecute = function() {
  let input = this.getInputData(0)
  if (this.inputs[0] && input) {
    try{
      this.setOutputData(0,toChecksumAddress(input))
    }
    catch(e){
      this.setOutputData(0,null)
    }
  }else{
    this.setOutputData(0,null)
  }
};

export default ToHex
