import React from "react";
import Dialog from "@material-ui/core/Dialog";
import "litegraph.js/css/litegraph.css";
import Grid from "@material-ui/core/Grid";
import { Button} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { t } from 'i18next';
import demos from './demosSchemes'


class SaveDialog extends React.Component {
  constructor(props){
    super(props)
    this.items = [];
    let i = 0;
    for(let demo of demos){
        if(!demo.langs || demo.langs.includes(localStorage.getItem('language'))){
          this.items.push(
              this.makeItem(demo,i)
          )
          i+=1
        }
    }
  }


  makeItem(demo,index){
    let comp = demo.comp
    let onClick = async ()=>{
      let json = comp
      console.log("configure graph with:", json);
      if (json) {
        localStorage.setItem("litegraph", json);
        this.props.liteGraph.configure(JSON.parse(json));
      }
      this.props.setOpenDemosDialog(false)
    }
    let background = demo.background?`url("${demo.background}")`:'red'
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <div onClick={onClick}  style={{background,backgroundSize: 'cover',maxWidth:'100%', width:300, height:200, borderRadius:10,   margin:"0 auto", textAlign:"center", border:"1px solid #4f4f5f"}}>
              <div style={{width:'100%', background:"#353950",color:"white",paddingTop:5,paddingBottom:5, borderTopLeftRadius:10, borderTopRightRadius:10 }}>{t(demo.name)}</div>
            </div>
        </Grid>
    )
  }
  render() {
    return (
      <Dialog
        onClose={() => {
          this.props.setOpenDemosDialog(false);
        }}
        open={this.props.openDemosDialog}
        fullWidth={true}
        maxWidth="lg"
        className="modal"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
          <div className="modal-card">
            <div style={{color: "white", textAlign:'center', fontSize:30,paddingTop:30}}>
              {t("Demo-schemes")}
            </div>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ margin: 0, width: "100%", padding: "32px" }}
            >
              {this.items}
            </Grid>
          </div>
      </Dialog>
    );
  }
}



export default SaveDialog;
