import { t } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom'

class Upload extends React.Component{
  constructor(props) {
    super(props)
    this.addOutput("text", "string");
    this.addOutput("base64", "string");
    this.size = [400,120]
    this.base64 = ""
    this.files = null;
    this.fileRef = React.createRef();
    this.onChangeHandler=event=>{
       console.log("CHANGE OF INPUT!")
        console.log(event.target.files[0])
        this.files = event.target.files;
        var data = event.target.result;
        //console.log(this.graph.getNodeById(this.id))
        this.onDropFile(event.target.files[0]);
    }
  }
  onDrawBackground(ctx) {
    let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  
    if (this.flags.collapsed) {
      this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
    }else{
      this.render(
        <div onDrop={(ev)=>{
          console.log("Drop", ev);
          ev.preventDefault();
          if(ev?.dataTransfer?.files?.length>=1){
            this.onDropFile(ev.dataTransfer.files[0])
            this.files = ev.dataTransfer.files
            this.fileRef.current.files=ev.dataTransfer.files
          }
        }}
        style={{
          width: (this.size[0]-40)*canvasscale,
          height: (this.size[1]-10)*canvasscale,
          fontSize: 16*canvasscale,
          fontFamily: 'Arial',
        }}
        className="dropArea"
        >
          <div style={{textAlign:"left",padding:"10px 20px 0 20px", height:"calc(50% - 10px)",}}>
            Drag and Drop or
          </div>
          <input ref={this.fileRef} type="file" style={{width:"calc(100% - 40px)", height:"calc(50% - 5px)", padding:"5px 20px 0 20px", fontSize: 16*canvasscale, fontFamily: 'Arial'}} name="file" onChange={this.onChangeHandler} />
        </div>
      )
    }
  }

  onExecute() {
    try{
      this.fileRef.current.files=this.files
    }
    catch(e){console.error(e)}
    if(this.contents){
      this.setOutputData(0, this.contents);
      this.setOutputData(1,this.base64);
    }
  }

  onDropFile (file) {
    console.log("dropped file",file)
    this.file = file
    var that = this;
  
    var reader = new FileReader();
    reader.onload = (event) => {
      this.contents = event.target.result
      this.length = event.target.result.length
      this.setOutputData(0, this.contents);
    }
    var readerBase64 = new FileReader();
    readerBase64.onload = (event) => {
      this.base64 = event.target.result
      this.setOutputData(1, this.base64);
    }
    this.properties.file = JSON.stringify(file)
    //console.log("file:",file)
    this.name = file.name
    reader.readAsText(file)
    readerBase64.readAsDataURL(file);
  }

}

Upload.type = "Upload";
Upload.title = t("Upload");


export default Upload
