import { t } from "i18next";

function makeItem(item) {
  return (
    <div style={{ margin: 5 }} onClick={item.onClick}>
      <span
        className="material-symbols-outlined"
        style={{ cursor: "pointer" }}
        title={t(item.tooltip)}
      >
        {item.iconName}
      </span>
    </div>
  );
}

export default makeItem