
import { t } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom'

function Action() {
  this.addInput("",-1);
  this.properties = {
    value:""
  }
  this.size = [310,220]
}

Action.type = "Action";
Action.title = t("Action");

Action.prototype.onExecute = function() {
};

Action.prototype.getTitle = function() {
  return this.title
};

Action.prototype.onAction = function(name,value) {
  console.log("ACTION",name,value)
  this.value = JSON.stringify(value,null,2)
  this.properties.title = name
}

Action.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  if (this.flags.collapsed) {
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR JUST NOT SHOW IT?! THIS SEEMS WEIRD
  }else{
    this.render(
      <div>
        <pre style={{margin:10,background:"#262838",color:"#cccccc",textAlign:"left",
          width: (this.size[0]-45)*canvasscale,
          height: (this.size[1]-25)*canvasscale,
          fontSize: 12*canvasscale
        }}>
          {this.value}
        </pre>
      </div>
    )
};



};

export default Action
