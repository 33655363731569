
import { t } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom'

function Watch() {
  this.addInput("", 0, { label: "" });
  this.addOutput("", 0, { label: "" });
  this.value = 0;
  this.size = [300, 60];
  this.autosized = false
}

Watch.type = "Watch";
Watch.title = t("Watch");

Watch.prototype.onExecute = function() {
  if (this.inputs[0]) {
    this.value = this.getInputData(0);
  }
  this.setOutputData(0,this.value)
};

Watch.prototype.getTitle = function() {
  if (this.flags.collapsed) {
    return this.value;
  }
  if(this.title&&this.title!="Watch"){
    return this.title;
  }
  if(this.value){
    return (typeof this.value)
  }
  return this.title
};

Watch.toString = function(o) {
  return o
};

Watch.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  if(this.value&&!this.autosized){
    this.autosized=true;
    if(typeof this.value == "object"){
      this.size = [600,100]
    }else if(typeof this.value.toString != "function"){
      this.size[0] = Math.max(200,25+12 * Math.max(this.value.length,1))
    }else{
      this.size[0] = Math.max(200,25+12 * Math.max(this.value.toString().length,1))
    }
  }

  if (this.flags.collapsed) {
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR JUST NOT SHOW IT?! THIS SEEMS WEIRD
  }else{
    this.render(
      <div>
        <pre style={{textAlign:'left',overflow:'auto',width:(this.size[0]-25)*canvasscale,height:(this.size[1]-15)*canvasscale,fontSize:18*canvasscale, fontFamily: 'Arial',}}>{JSON.stringify(this.value,null,2)}</pre>
      </div>
    )
};



};

export default Watch
