import { t } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom'

function Timestamp() {
  this.addOutput("", "number");
  this.size = [200, 60];
}

Timestamp.type = "Timestamp";
Timestamp.title = t("Timestamp");

Timestamp.prototype.onExecute = async function() {
  this.setOutputData(0,Date.now())
};


export default Timestamp
