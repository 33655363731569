import { t } from "i18next";
import AceEditor from "react-ace";

function CustomNode() {
  this.addInput("input", "object");
  this.addInput("code", "string");
  this.addOutput("output", "object");
  this.size = [150,50]
  this.properties = { execute: true };
}

CustomNode.type = "CustomNode";
CustomNode.title = t("CustomNode");
CustomNode.desc = "CustomNode";

CustomNode.prototype.onExecute = function () {
  let code = this.getInputData(1);
  if(!code){
    return;
  }
  let input = this.getInputData(0);
  if(typeof input !== "object" || input==null){
    return;
  }
  input = JSON.parse(JSON.stringify(input))
  let output;
  if (this.properties.execute) {
    try {
      eval(code);
    } catch (e) {
      this.properties.execute = false;
      global.setSnackbar({msg:"Ошибка во время выполнения кода из CustomNode. Исправте код и включите галочку execute в свойствах"})
      console.error(e);
    }
  }
  this.setOutputData(0, output);
};
CustomNode.prototype.onInputDblClick = function(index,e){
  if(index===1){
    var code_node = global.LiteGraphJS.LiteGraph.createNode("Input/Code");
      code_node.pos = [e.canvasX - 380, e.canvasY];
      code_node.properties.value =  "output=input\noutput.value += 1"
      this.graph.add(code_node);
      setTimeout(() => {
        code_node.connect(0, this, index);
      }, 1)
      return true; // preventDefault
  }
  return false;
}
export default CustomNode;
