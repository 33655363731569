import def from "../schemes/default.json";
import def_en from "../schemes/default_en.json";
import defScreenshot from "../schemes/screenshots/default.json"
import empty from "../schemes/empty.json";
import emptyScreenshot from "../schemes/screenshots/empty.json"
import vis from "../schemes/vis.json";
import visScreenshot from "../schemes/screenshots/vis.json"
import keys from "../schemes/keys.json"
import keysScreenshot from "../schemes/screenshots/keys.json"
import customNode from "../schemes/customNode.json"
import customNodeScreenshot from "../schemes/screenshots/customNode.json"
let demos = [
  {
    comp: def,
    name: "Default",
    background: defScreenshot,
    langs: ['ru']
  },
  {
    comp: def_en,
    name: "Default",
    background: defScreenshot,
    langs: ['en']
  },
  {
    comp: empty,
    name: "Empty",
    background:emptyScreenshot
  },
  {
    comp: vis,
    name: "Visualization how blocks work in blockchain",
    background:visScreenshot,
  },
  {
    comp: keys,
    background:keysScreenshot,
    name: "Keys, adrress and mnemonic",
  },
  {
    comp: customNode,
    background:customNodeScreenshot,
    name: "CustomNode"
  }
];
for (let i in demos){
  demos[i].comp = JSON.stringify(demos[i].comp)
}
export default demos;