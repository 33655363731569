import makeItem from "./makeItem";

function Copy(props) {
  const onClick = async () => {
    try {
      global.graph.canvas.copyToClipboard();
    } catch (e) {
      console.error(e);
    }
  }

  let item = {
    tooltip: "Copy [ctrl+c]",
    iconName: "content_copy",
    onClick
  };

  return makeItem(item);
}
export default Copy;
