import React from "react";
import Dialog from "@material-ui/core/Dialog";
import "litegraph.js/css/litegraph.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { t } from 'i18next';

const useStyles = makeStyles({
  button: {
    width: 200
  }
});



function SaveDialog(props) {
  const {
    liteGraph,
    setOpenSaveDialog,
    openSaveDialog,
    dynamicWidth,
  } = props;

  const classes = useStyles();

  const [saveType, setSaveType] = React.useState(null);

  const [compressed, setCompressed] = React.useState();


  const handleClose = () => {
    setOpenSaveDialog(false);
    setSaveType(null);
  };

  React.useEffect(() => {
    if (liteGraph) {
      setCompressed(JSON.stringify(liteGraph.serialize()));
    }
  });

  const download = async () => {
    console.log("SAVING COMPRESSED", compressed);

    let webfile = compressed;

    var file = new Blob([webfile]);
    var url = URL.createObjectURL(file);
    var element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute(
      "download",
      "no.code.webloc"
    );
    element.style.display = "none";
    if (document.body) {
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      setTimeout(function() {
        URL.revokeObjectURL(url);
      }, 1000 * 60);
      handleClose();
    }
  };

  return (
    <Dialog
      onClose={() => {
        handleClose();
      }}
      open={openSaveDialog}
      maxWidth="md"
      className="modal"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      {saveType === null && (
        <div className="modal-card">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ margin: 0, width: "100%", padding: "32px" }}
          >
            <Grid item>
                <Button
                style={{width:"100%"}}
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={download}
                  startIcon={<GetAppIcon />}
                >
                  {t("Download")}
                </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Dialog>
  );
}

export default SaveDialog;
