import { t } from "i18next";
import Input from "./Input";
import {copyPrototype} from '../../utils/nodeUtils'
function Trigger() {
  this.addOutput("",-1);
  this.is_input_node = true;
  this.name_in_graph = "";
  this.properties = {
    name: "",
    type: -1
  };

  this.name_widget = this.addWidget(
    "text",
    "Name",
    this.properties.name,
    (v) => {this.setProperty("name", v)}
  );

  this.widgets_up = true;
  this.size = [180, 60];
}
Trigger.prototype = copyPrototype(Input);
Trigger.type = "Trigger";
Trigger.title = t("Trigger");

Trigger.prototype.onAction = function(action, param) {
  this.triggerSlot(0, param);
};



export default Trigger
