import { t } from "i18next";

function Input() {
  this.addOutput("", 0);
  this.is_input_node = true;
  this.name_in_graph = "";
  this.properties = { name: "" };

  this.name_widget = this.addWidget(
    "text",
    "Name",
    this.properties.name,
    (v) => {
      this.setProperty("name", v);
    }
  );

  this.widgets_up = true;
  this.size = [180, 60];
}

Input.type = "Input";
Input.title = t("Input");
Input.prototype.countWithSameName = function (name) {
  let allInputNodes = this.graph._nodes.filter((x) => x.is_input_node);
  return allInputNodes.filter((x) => x.name_in_graph === name).length;
};
Input.prototype.onNameChanged = function (v) {
  if (v === this.name_in_graph) {
    return;
  }
  if (!this.graph) {
    // если graph все же не успел проставиться, то сбрасываем
    this.name_widget.value = "";
    this.name_in_graph = "";
    return;
  }
  if(v === "enabled" ){
    this._removeInput();
    this.name_widget.value = "";
    this.name_in_graph = "";
    return;
  }
  if (v === "") {
    this._removeInput();
  } else {
    let countWithSameCurrName = this.countWithSameName(this.name_in_graph);
    let countWithSameNextName = this.countWithSameName(v);
    if (this.name_in_graph && countWithSameCurrName === 1) {
      if (countWithSameNextName === 0) {
        this.graph.renameInput(this.name_in_graph, v);
      } else {
        this.graph.removeInput(this.name_in_graph);
      }
    } else {
      this.graph.addInput(v, this.properties.type);
    }
  }
  this.name_widget.value = v;
  this.name_in_graph = v;
};
Input.prototype.onPropertyChanged = function (name, v) {
  if (name == "name") {
    setTimeout(() => this.onNameChanged(v), 300); //т.к. при копировании graph не проставлен нужно немного подождать
  }
};

Input.prototype.getTitle = function () {
  if (this.flags.collapsed) {
    return this.properties.name;
  }
  return this.title;
};

Input.prototype.onExecute = function () {
  var name = this.properties.name;
  var data = this.graph.inputs[name];
  this.setOutputData(0, data?.value);
};

Input.prototype._removeInput = function () {
  let countWithSameCurrName = this.countWithSameName(this.name_in_graph);
  if (countWithSameCurrName == 1) {
    this.graph.removeInput(this.name_in_graph);
  }
};
Input.prototype.onRemoved = function () {
  this._removeInput();
};

global.LiteGraphJS.GraphInput = Input;

export default Input;
