import Dragger from './../Dragger.js';
import Grid from '@material-ui/core/Grid';
import { t } from 'i18next';


export default function TopMenu(props) {
  const { drawing, menu, setMenu, height, width, setDrawing, setDrawingColor } = props.TopMenuProps;
  
  const mouseEnter = (name, e) => {
    if (e.pageY > 60 && e.pageY < height - 60) {
      setMenu("");
    } else {
      setMenu(name);
    }
  };

  const mouseLeave = (e) => {
    setMenu("");
  };

  let customNodes = [];

  for (let n in global.customNodes) {
    //console.log("GRID",global.customNodes[n])
    //if(global.customNodes[n].name!="Special" && global.customNodes[n].name!="Modules"){
    if (!drawing && global.customNodes[n].name == menu) {
      let positionStyle = { position: "absolute" };
      let style = {
        borderBottom: "3px solid #888888",
        whiteSpace: "nowrap",
        letterSpacing: -1,
        fontSize: 14,
        margin: 4,
        borderRadius: "8px 8px 8px 8px",
        padding: 6,
        textAlign: "center",
        color: "#FFFFFF",
        backgroundColor: "#" + global.customNodes[n].color,
      };
      if (n < 6) {
        positionStyle.left = 0;
      } else {
        positionStyle.right = 0;
      }

      let items = [];
      let itemspace = 40;
      for (let i in global.customNodeItems[global.customNodes[n].name]) {
        let item = global.customNodeItems[global.customNodes[n].name][i];
        items.push([
          <div className="submenu__btn_wrapper" key={"wr" + i}>
            <Dragger
              key={"dragger" + n + "_" + i}
              name={item.title}
              drop={(name, x, y) => {
                //console.log("DO A DROP AT ",name,x,y)
                setMenu("");
                var node_watch = global.LiteGraphJS.LiteGraph.createNode(
                  item.type
                );
                node_watch.pos = [
                  x - 40 + global.graph.canvas.visible_area[0],
                  y + global.graph.canvas.visible_area[1],
                ];
                //console.log("looking in",,liteGraph,liteGraph._is_subgraph)
                global.graph.canvas.graph.add(node_watch);
              }}
            >
              <div
                onMouseUp={() => {
                  if (menu) {
                    setMenu("");
                    var node_watch = global.LiteGraphJS.LiteGraph.createNode(
                      item.type
                    );
                    global.graph.canvas.selectNode(node_watch);
                    node_watch.pos = [
                      width / 2 - 40 + global.graph.canvas.visible_area[0],
                      height / 2 + global.graph.canvas.visible_area[1],
                    ];
                    //console.log("looking in",,liteGraph,liteGraph._is_subgraph)
                    global.graph.canvas.graph.add(node_watch);
                  }
                }}
                className="submenu__btn"
              >
                {item.title}
              </div>
            </Dragger>
          </div>,
        ]);
      }

      customNodes.push(
        <div
          className={
            "topmenu__btn topmenu__btn_opened " + global.customNodes[n].slug
          }
          style={{ position: "relative" }}
          key={"girdder" + n}
          onMouseLeave={mouseLeave}
          onClick={() => {
            setMenu(global.customNodes[n].name);
          }}
        >
          <span
            className={
              "material-symbols-outlined " + global.customNodes[n].slug
            }
          >
            {global.customNodes[n].icon}
          </span>

          {width > 800 ? t(global.customNodes[n].name) : ""}

          <div className={"topmenu__btn_submenu " + global.customNodes[n].slug}>
            {items}
          </div>
        </div>
      );
    } else {
      if (drawing) {
        if (
          global.customNodes[n].name != "Modules" &&
          global.customNodes[n].name != "Special" &&
          global.customNodes[n].name != "Components"
        ) {
          customNodes.push(
            <Grid
              key={"grd" + n}
              onMouseLeave={mouseLeave}
              onMouseEnter={mouseEnter.bind(this, global.customNodes[n].name)}
              item
              xs={1}
              style={{
                cursor: "pointer",
                letterSpacing: -3,
                fontFamily: "'Rubik Mono One', sans-serif",
              }}
              onClick={(e) => {
                //console.log("SET COLOR",global.customNodes[n].color)
                setDrawingColor("#" + global.customNodes[n].color);
                global.graph.canvas.drawing = "#" + global.customNodes[n].color;
                setDrawing("#" + global.customNodes[n].color);
                global.graph.canvas.setDirty(true);
                global.graph.canvas.graph.change();
              }}
            >
              <div
                style={{
                  borderRadius: "0px 0px 8px 8px",
                  padding: 6,
                  paddingTop: 16,
                  paddingBottom: 8,
                  textAlign: "center",
                  color: "#222222",
                  height: 20,
                  backgroundColor: "#" + global.customNodes[n].color,
                  opacity: 0.6,
                }}
              ></div>
            </Grid>
          );
        }

        //setDrawingColor
      } else {
        customNodes.push(
          <div
            className="topmenu__btn"
            key={"grd" + n}
            onMouseLeave={mouseLeave}
            onMouseEnter={mouseEnter.bind(this, global.customNodes[n].name)}
            onClick={(e) => {
              if (e.pageY > 60) {
                setMenu("");
              } else {
                setMenu(global.customNodes[n].name);
              }
            }}
          >
            <span
              className={
                "material-symbols-outlined " + global.customNodes[n].slug
              }
            >
              {global.customNodes[n].icon}
            </span>

            {width > 800 ? t(global.customNodes[n].name) : ""}
          </div>
        );
      }
    }

    //}
  }
  return customNodes
}
