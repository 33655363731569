import makeItem from "./makeItem";

function Select(props) {
  const onClick = async () => {
    //console.log(JSON.stringify(global.graph.canvas.graph))
    global.graph.canvas.selectNodes();
  };

  let item = {
    tooltip: "Select All [ctrl+a]",
    iconName: "select_all",
    onClick,
  };

  return makeItem(item);
}
export default Select;
