import { t } from "i18next";

var Web3 = require("web3");

const defaultProvider = "https://mainnet.infura.io/v3/e59c464c322f47e2963f5f00638be2f8";

function ENS() {
  this.addInput("name", "string");
  this.addOutput("address", "string");
  this.properties = { provider: defaultProvider };
  this.cached = false;
  this.cachedAddress = false;
  this.size[0] = 210;
}

ENS.type = "ENS";
ENS.title = t("ENS");

ENS.prototype.onAdded = function() {
  this.connectWeb3();
};

ENS.prototype.connectWeb3 = function() {
  if (this.properties.provider) {
    try {
      this.web3 = new Web3(this.properties.provider);
      this.cached = false;
      this.cachedAddress = false;
    } catch (e) {
      console.log(e);
    }
  }
};

ENS.prototype.onExecute = async function() {
  let input = this.getInputData(0);

  if (input && typeof input.indexOf == "function") {

    //FORCE THEM TO TYPE THE .ETH AT THE END FOR NOW ... IT WAS FAILING AND COULDN'T BE CAUGHT IF NOT

    if (input && (!this.cached || this.cached != input)) {
      this.cached = input;

      try {
        this.value = await this.web3.eth.ens.getAddress(this.cached);
      } catch (e) {
        console.log(e);
        this.value = undefined;
      }
      if (this.value) this.value = this.value.toLowerCase();
    }
  }


  this.setOutputData(0, this.value);
};

ENS.prototype.onPropertyChanged = async function(name, value) {
  this.properties[name] = value;
  if (name == "provider") {
    this.connectWeb3();
  }
  return true;
};

export default ENS;
