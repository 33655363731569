import { Component } from "react";
import i18next from "i18next";
export default class LangSelector extends Component {
    constructor (props){
        super(props);
        let lang = localStorage.getItem('language')
        this.state = {
            langs:[
                {title:'English',short:'en'},
                {title:'Русский',short:'ru'},
            ],
            selected: lang
        }
    }

    getLangDiv(lang) {
        let cls = "langItem "
        if (lang.short == this.state.selected) cls += "selected";
        return <div className="langWrapper" key={lang.short}><div className={cls} onClick={this.onClick.bind(this,lang)}>{lang.title}</div></div>
    }

    onClick(lang) {
        localStorage.setItem('language', lang.short)
        this.setState({...this.state, selected:lang.short})
        document.location.reload()
    }

    render() {
        if (this.props.hide) return null;
        return <div className="langSelector">
                {this.state.langs.map(lang=>this.getLangDiv(lang))}
            </div>
    }
}
