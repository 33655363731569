import React from 'react';
import ReactDOM from 'react-dom'
import Blockies from 'react-blockies';

import MaterialButton from '@material-ui/core/Button';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import {t} from 'i18next'
function Button() {
  this.addInput("", -1);
  this.addOutput("", -1);
  this.addOutput("", "number,boolean");
  this.properties =  {value: t("click me"),count:0}
  this.size = [220, 70];
}

Button.title = t("Button");
Button.type = "Button"

Button.prototype.onConnectionsChange = function(args){
  console.log("onConnectionsChange",args)
}

Button.prototype.onExecute = function() {
  let input = this.getInputData(0)
  if (this.inputs[0] && typeof input != "undefined" && this.properties.value != input ) {
    this.properties.value = this.getInputData(0);
  }
  this.setOutputData(0,this.properties.value);
  this.setOutputData(1,this.properties.count);
};

Button.prototype.getTitle = function() {
  if (this.flags.collapsed && this.properties.value) {
    return this.properties.value
  }
  return this.title;
};

Button.prototype.handle = function(e) {
    this.properties.value = e.target.value
    this.setOutputData(0,this.properties.value);
    this.setOutputData(1,this.properties.count);
}

Button.prototype.onAction = function(e) {
  this.properties.count = this.properties.count+1
  this.trigger()
}
const theme = createTheme({ palette: { primary: {main:'#20729c'} } })
Button.prototype.onDrawBackground = function(ctx) {
  //console.log(this)
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;
  if (this.flags.collapsed) {
    /*this.render(
      <div>

      </div>
    )*/
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div style={{marginTop:5}}>
        <MuiThemeProvider theme={theme}>
          <MaterialButton
              style={{
                width:(this.size[0]-60)*canvasscale,
                height:(this.size[1]-15)*canvasscale,
                fontSize:15*canvasscale,
                fontFamily: 'Arial',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="contained" color="primary" size={"large"} onClick={()=>{
              this.properties.count = this.properties.count+1
              this.trigger()
            }}>
            {this.properties.value}
         </MaterialButton>
        </MuiThemeProvider>


      </div>
    )
  }


};




export default Button
