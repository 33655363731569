import makeItem from "./makeItem";

function Reorient(props) {
  const { setDrawing, setSelectToolActive } = props.RightMenuProps;
  const onClick = async () => {
    if (global.graph.canvas.search_box) global.graph.canvas.search_box.close();
    global.graph.canvas.ds.reset();
    global.graph.canvas.setDirty(true);
    global.graph.canvas.graph.change();
    setDrawing("");
    global.graph.canvas.drawing = false;
    global.graph.canvas.selectToolActive = false;
    setSelectToolActive(global.graph.canvas.selectToolActive);

    let reactDiv = document.getElementById("reactElements");
    if (reactDiv) reactDiv.innerHTML = "";
  };

  let item = {
    tooltip: "Reorient [esc key]",
    iconName: "aspect_ratio",
    onClick,
  };

  return makeItem(item);
}
export default Reorient;
