import React from 'react';
import ReactDOM from 'react-dom'
import Blockies from 'react-blockies';

import { Input, FilledInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

function Text() {
  this.addInput("", 0);
  this.addOutput("", "string");
  this.properties =  {blockieSize: 50,placeholder:"enter text here",value:""}
  this.size = [300, 50];
}

Text.type = "Text";
Text.title = t("Text");

Text.prototype.onConnectionsChange = function(args){
  console.log("onConnectionsChange",args)
}

Text.prototype.onExecute = function() {
  let input = this.getInputData(0)
  if (this.inputs[0] && typeof input != "undefined" && this.properties.value != input ) {
    this.properties.value = input;
  }
  this.setOutputData(0,this.properties.value);
};

Text.prototype.getTitle = function() {
  if (this.flags.collapsed && this.properties.value) {
    return this.properties.value
  }
  return this.title;
};

Text.prototype.handle = function(e) {
    this.properties.value = e.target.value
    this.setOutputData(0,this.properties.value);
    this.onDrawBackground()
}

Text.prototype.onDrawBackground = function(ctx) {
  let canvasscale = (this.graph.canvas?.ds.scale) ?? 1;

  if (this.flags.collapsed) {
    /*this.render(
      <div>

      </div>
    )*/
    this.destory()///SHOULD WE DESTORY THE ELEMENT FROM THE DOM OR
  }else{
    this.render(
      <div>
        <form className={"SOMECONTAINERCLASS"} noValidate autoComplete="off">
          <Input
            style={{
              color:"#FFFFFF",fontSize:this.properties.fontSize,
              width: (this.size[0]-40)*canvasscale,
              height: (this.size[1]-10)*canvasscale,
              fontFamily: 'Arial',
              fontSize: 16*canvasscale,
              marginTop: 5*canvasscale,
              maxHeight:40
            }}
            id={"react-input-"+this.id}
            label="Name"
            placeholder={this.properties.placeholder}
            value={this.properties.value}
            onChange={Text.prototype.handle.bind(this)}
            margin="none"
            variant="outlined"
            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
          />
        </form>
      </div>
    )
  }


};




export default Text
