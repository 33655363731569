const touchHandler = (event) => {
  const touches = event.changedTouches;
  const first = touches[0];
  let type = "";
  switch (event.type) {
    case "touchstart":
      type = "mousedown";
      break;
    case "touchmove":
      type = "mousemove";
      if (global.showLibrary == true) {
      } else {
        event.preventDefault();
      }
      break;
    case "touchend":
      type = "mouseup";
      break;
    default:
      return;
  }

  event.preventDefault();

  if (
    (event.touches?.length === 1 &&
      ["touchstart", "touchmove"].includes(event.type)) ||
    event.type == "touchend"
  ) {
    var simulatedEvent = new MouseEvent(type, {
      screenX: first.screenX,
      screenY: first.screenY,
      clientX: first.clientX,
      clientY: first.clientY,
      button: 0,
    });
    first.target.dispatchEvent(simulatedEvent);

    // if (global.graph?.canvas?.search_box && event.type == 'touchend') global.graph?.canvas?.search_box.close();
  }

  if (event.touches?.length === 2 && event.type == "touchstart") {
    global.tm = true;
    event.preventDefault();
    event.stopPropagation();
    global.scaling = true;
    global.scalingDistStart =
      global.scalingDistEnd =
      global.scalingDist =
        Math.hypot(
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY
        );
  }

  if (event.touches?.length === 2 && event.type == "touchmove") {
    global.tm = true;
    if (global.scaling) {
      event.preventDefault();
      global.scalingDistEnd = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );

      // процентная длина жеста от ширины экрана
      let zoomzoom =
        ((global.scalingDistEnd - global.scalingDistStart) * 100) /
          window.innerWidth /
          100 +
        global.graph.canvas.ds.scale;

      if (global.graph?.canvas) {
        if (zoomzoom < 0.5) {
          zoomzoom = 0.5;
        } else if (zoomzoom > 3) {
          zoomzoom = 3;
        }

        if (zoomzoom == global.graph.canvas.ds.scale) {
          return;
        }

        global.graph.canvas.ds.scale = zoomzoom;
        global.graph.canvas.resize();
      }

      global.scalingDistStart = global.scalingDist = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );
    }
  }

  if (event.type == "touchend") {
    if (global.scaling) {
      global.scaling = false;
    }
  }
};
export default touchHandler;
